import React from "react"

import Layout from "../components/Layout"
import Form from "../components/Form"

const Contact = (): React.ReactNode => (
  <div>
    <Layout>
      <Form/>
    </Layout>
  </div>
)

export default Contact
