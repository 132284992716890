import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'

import {
  useTheme,
} from '@material-ui/core/styles'

const defaultValues = {
  name: '',
  email: '',
  subject: '',
  message: '',
}
const Form = () => {
  const theme = useTheme()

  const [formValues, setFormValues] = useState(
    defaultValues,
  )
  const handleInputChange = e => {
    const { name, value } = e.target
    setFormValues({
      ...formValues,
      [name]: value,
    })
  }
  const handleSliderChange = name => (e, value) => {
    setFormValues({
      ...formValues,
      [name]: value,
    })
  }
  const handleSubmit = event => {
    event.preventDefault()
    console.log(formValues)
  }
  return (
    <form onSubmit={handleSubmit}>
      <Box
        pl={["3rem", "5rem"]}
        mt={["1rem","2rem","3rem"]}>
        <Box p={['1rem', '2rem', '3rem']}>
          <Box
            fontWeight="fontWeightLight"
            fontFamily="Pacifico"
            color={theme.palette.primary.main}
            fontSize="h1.fontSize"
            textAlign="center"
          >
           Get in touch!
          </Box>
        </Box>
      <Grid
        container
        spacing={7}
        direction="column"
      >
        <Grid item xs={10} lg={4}>
          <TextField
            id="name-input"
            name="name"
            label="Name"
            type="text"
            value={formValues.name}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={10} lg={4}>
          <TextField
            id="email-input"
            name="email"
            label="Email"
            type="email"
            value={formValues.email}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={10} lg={4}>
          <TextField
            id="subject-input"
            name="subject"
            label="Subject"
            type="text"
            value={formValues.subject}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={10} lg={4}>
          <Typography variant="body1">
            Message
          </Typography> 
          <TextareaAutosize
            id="message-input"
            rows={12}
            name="message"
            style={{ width: '100%' }}
            type="text"
            value={formValues.textArea}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={10} lg={6}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          size="large"
        >
          Submit
        </Button>
          </Grid>
      </Grid>
        </Box>
    </form>
  )
}
export default Form
